
export const setCSSVariable = (variable, value) => {
  document.documentElement.style.setProperty(variable, value);
};

export const applyLightTheme = () => {
  setCSSVariable("--primary-100", "#E3F2FD");  // Açık mavi
  setCSSVariable("--primary-200", "#90CAF9");  // Orta mavi
  setCSSVariable("--primary-300", "#42A5F5");  // Daha koyu mavi
  setCSSVariable("--accent-100", "#80D8FF");   // Parlak açık mavi
  setCSSVariable("--accent-200", "#40C4FF");   // Daha parlak açık mavi
  setCSSVariable("--text-100", "#0D47A1");     // Koyu mavi
  setCSSVariable("--text-200", "#1976D2");     // Orta koyu mavi
  setCSSVariable("--bg-100", "#805ad5");       // Çok açık mavi
  setCSSVariable("--bg-200", "#BBDEFB");       // Açık mavi
  setCSSVariable("--bg-300", "#90CAF9");       // Orta mavi
};

export const applyDarkTheme = () => {
  setCSSVariable("--primary-100", "#0D47A1");  // Koyu mavi
  setCSSVariable("--primary-200", "#1976D2");  // Orta koyu mavi
  setCSSVariable("--primary-300", "#1E88E5");  // Parlak mavi
  setCSSVariable("--accent-100", "#29B6F6");   // Parlak açık mavi
  setCSSVariable("--accent-200", "#03A9F4");   // Daha parlak açık mavi
  setCSSVariable("--text-100", "#E3F2FD");     // Çok açık mavi
  setCSSVariable("--text-200", "#BBDEFB");     // Açık mavi
  setCSSVariable("--bg-100", "#0D47A1");       // Koyu mavi
  setCSSVariable("--bg-200", "#1976D2");       // Orta koyu mavi
  setCSSVariable("--bg-300", "#1E88E5");       // Parlak mavi
};
